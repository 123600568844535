// function to create the paylod with necessary fields based on request
export default function getPayload({
  request,
  inputsData,
  configValues,
  recaptchaToken,
}) {
  for (let key in configValues.actions[request]) {
    if (key === "fields") {
      const dataFields = configValues.actions[request][key]
        .map((field) => {
          switch (field.label) {
            case "Relationship Type":
              return {
                value: inputsData["defineYourRelationshipWithSeagen"],
                label: field.label,
                _id: field._id,
              };

            case "First and Last Name":
              return {
                value: inputsData["name"],
                label: field.label,
                _id: field._id,
              };
            case "Phone Number":
              return {
                value: inputsData["phoneNumber"],
                label: field.label,
                _id: field._id,
              };

            case "Additional Information":
              return {
                value: inputsData["additionalInfo"],
                label: field.label,
                _id: field._id,
              };
            case "Please provide additional information to clarify request scope":
              return {
                value: inputsData["additionalInformation"],
                label: field.label,
                _id: field._id,
              };
            case "Email":
              return {
                value: inputsData["email"],
                label: field.label,
                _id: field._id,
              };
            default:
              console.warn(`unknown field ${field.label}`);
          }
          return null;
        })
        .filter((field) => field);

      const primaryEmail = inputsData["email"] ? inputsData["email"] : "";

      const dataInputs = {
        data: {
          providedFields: dataFields,
          primaryEmail,
          providedRequests: [request],
          recaptchaToken,
        },
        meta: {
          id: `${configValues.settings}`,
          pageDataId: `${configValues._id}`,
          type: "dsar",
        },
      };
      return dataInputs;
    }
  }
}
