import React from "react";
import { Container, Card } from "react-bootstrap";

function Acknowledgement(props) {
  const { id } = props;

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Body className="ml-2 text-justify">
            <Card.Title className="mb-5 font-weight-bold">
              <u>
                <h3 className="text-center">Confirmation</h3>
              </u>
            </Card.Title>
            <Card.Text className="mb-5 acknowledgement">
              <Card.Text>
                We’ve sent you a link to continue with your request. Please
                proceed to your inbox to look for an email from{" "}
                <a
                  href="mailto: no-reply@wirewheel.io"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="no-reply@wirewheel.io"
                >
                  no-reply@wirewheel.io
                </a>
                . If you do not receive the email in the next few hours, please
                check your SPAM filters or resubmit your request.
              </Card.Text>

              <Card.Text>
                Your Request ID: <strong>{id}</strong>. Please save this for
                your records.
              </Card.Text>

              <Card.Text>
                The email will prompt you to confirm your request.
              </Card.Text>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
