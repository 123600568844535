import React from "react";

function requestTypeIntro(props) {
  const { requestType } = props;

  let text = "";

  switch (requestType) {
    case "access":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws. We respect the privacy and security of your personal information. To keep your information secure, we will only send you information that we can reasonably link to the email address you provide in this form.";
      break;
    case "delete":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws. We respect the privacy and security of your personal information. To keep your information secure, we will only delete information that we can reasonably link to the email address you provide in this form.";
      break;
    case "erasure":
      text =
        "We respect the privacy and security of your personal information. To keep your information secure, we will only erase information that we can reasonably link to the email address you provide in this form.";
      break;
    case "donotsell":
      text =
        "We respect the privacy and security of your personal information. Your request will apply only to the information we can reasonably link to the email address you provide in this form.";
      break;
    case "restrictProcessing":
      text =
        "To exercise your right to restrict processing, please provide your email address. Your request will only apply to information that we can reasonably link to the email address you provide in this form.";
      break;
    case "correction":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws. We respect the privacy and security of your personal information. To update, please provide your email address in the form below.";
      break;

    default:
      break;
  }

  return (
    <div>
      <section className="container">
        <p className="text-justify mt-3">{text}</p>
      </section>
    </div>
  );
}

export default requestTypeIntro;
