import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { getService } from "../shared/components/restApi";
import Acknowledgement from "./Acknowledgement";
import SrrHeader from "../shared/components/SrrHeader";
import RequestForm from "../shared/components/RequestForm";

function Other(props) {
  const { id } = props.match.params;
  let src = "";
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [configValues, setConfigValues] = useState({});
  const [requestId, setRequestId] = useState("");

  switch (id) {
    case "USA":
      src = `${process.env.REACT_APP_SEAGEN_SRR_CONFIG_USA_OTHER}`;
      break;
    case "EU":
      src = `${process.env.REACT_APP_SEAGEN_SRR_CONFIG_EU_OTHER}`;
      break;
    default:
      break;
  }

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    const url = src;
    let configOptions = {
      method: "GET",
      url,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});
          setConfigValues({ actions, settings: settingsId, _id: settingsId });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [src]);

  return (
    <div>
      <SrrHeader
        heading="Additional Information To Clarify Request Scope"
        breadcrumb_path1="SEAGEN TRUST CENTER"
        breadcrumb_path2="Other"
      />

      <Container className="pt-2">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <RequestForm
            configValues={configValues}
            requestType="category-access"
            enableLoader={handleLoader}
            getAcknowledgement={handleAcknowledgement}
            getSubmitRequestId={handleSubmitRequestId}
          />
        )}
      </Container>
    </div>
  );
}

export default Other;
